.x-overlay {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	.min-height-100vh();
	z-index: @z-index-overlay;
	.bg-rgba(102, 102, 102, 0.5);
	white-space: normal;
	
	.x-body {
		position: relative;
		height: 100%;
		max-width: 100%;
		margin: 0;
		padding: 10px 20px;
		color: #fff;
		.bg-rgba(0, 0, 0, 0.9);
		
		.x-close {
			cursor: pointer;
			position: absolute;
			top: 2px;
			right: 4px;
			font-weight: bold;
			font-size: 14px;
			text-shadow: 0 1px 0 #fff;
			
			&:hover {
				text-shadow: 0 1px 0 #666;
			}
		}
		
		a,
		.text-danger,
		.text-success,
		.help-block {
			color: #fff;
		}
		legend {
			color: #fff;
			font-weight: bold;
		}
		.form-group {
			.icon-f,
			.fa {
				&.text-success {
					color: @state-success-text;
				}
			}
			
			.icon-f,
			.fa {
				&.text-danger {
					color: @state-danger-text;
				}
			}
		}
	}
	
	&.x-overlay-box {
		padding: 0 40px;
		
		.x-body {
			height: auto;
			margin: 0 auto;
			padding: 25px;
			font-size: 16px;
			line-height: 1.5;
		}
	}
	
	&.x-overlay-fullscreen {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		
		.x-body {
			position: absolute;
			top: 10px;
			left: 10px;
			right: 10px;
			bottom: 10px;
			overflow: hidden;
			height: auto;
			padding: 25px 15px 25px 25px;
			
			.x-content {
				padding-right: 10px;
				overflow-x: hidden;
				overflow-y: auto;
			}
		}
	}
	
	&.auto-width-popup,
	&.contextual-popup {
		text-align: center;
		
		.x-body {
			display: inline-block;
			text-align: left;
		}
	}
	
	&.contextual-popup {
		.x-body {
			.contextual-popup-message {
				font-size: 17px;
				margin-bottom: 14px;
			}
			
			.contextual-popup-actions {
				padding: 0 10px 0 20px;
				text-align: right;
				
				.btn {
					font-size: 13px;
				}
			}
		}
	}
	
	h1, h2, h3, h4, h5, h6 {
		margin: 10px 0 20px 0;
		line-height: 1.3;
	}
}

body.x-overlay-floating-opened {
	.height-100vh();
	.max-height-100vh();
	max-width: 100%;
	overflow: hidden!important;
	position: sticky!important;
	width: 100%;
}