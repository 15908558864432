.pop-info-btn {
	cursor: pointer;
	display: inline-block;
	padding: 0 4px;
}
.pop-info-cont {
	display: none;
}

.x-popup {
	position: absolute;
	z-index: @zindex-popup;
	padding: @tooltip-arrow-width 0;
	.x-popup-content {
		background: @tooltip-bg;
		color: @tooltip-color;
		padding: 5px 15px;
		overflow-x: hidden;
		overflow-y: auto;
		zoom: 1;
		a {
			color: darken(@tooltip-color, 15%);
			text-decoration: underline;
			&:hover,
			&:active {
				color: #fff;
			}
		}
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			li {
				padding: 10px 20px;
				&:hover {
					background: @blue-theme-darker;
				}
				a {
					text-decoration: none;
					display:block;
				}
			}
		}
		p {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.x-popup-arrow {
		position: absolute;
		width: 0;
		height: 0;
		border-color: transparent;
		border-style: solid;
	}
	.x-popup-close {
		cursor: pointer;
		position: absolute;
		color: @tooltip-color;
		font-size: 13px;
		right: 5px;
		top: 8px;
		span span {
			display: none;
		}
	}
	&.above {
		margin-bottom: -@tooltip-arrow-width;
		.x-popup-arrow {
			bottom: 0;
			left: 10px;
			margin-left: -@tooltip-arrow-width;
			border-width: @tooltip-arrow-width @tooltip-arrow-width 0;
			border-top-color: @tooltip-bg;
		}
		&.right .x-popup-arrow {
			left: auto;
			right: 10px;
		}
	}
	&.below {
		margin-top: -@tooltip-arrow-width;
		.x-popup-arrow {
			top: 0;
			left: 10px;
			margin-left: -@tooltip-arrow-width;
			border-width: 0 @tooltip-arrow-width @tooltip-arrow-width;
			border-bottom-color: @tooltip-bg;
		}
		&.right .x-popup-arrow {
			left: auto;
			right: 10px;
		}
	}
}