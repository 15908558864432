/*
	@screen-xlg-max : 1919
		--- XLG
	@screen-xlg-min : 1440
	@screen-lg-max : 1439
		--- LG
	@screen-max : 1280
	@screen-lg-min : 1200
	@screen-md-max : 1199
		--- MD
	@screen-md-min : 992
	@screen-sm-max : 991
		--- SM
	@screen-sm-min : 768
	@screen-xs-max : 767
		--- XS
	@screen-xs-min : 480
	@screen-xxs-max : 479
		--- XXS
 */

.mobile-only-show,
.mobile-show-inline,
.mobile-only-show-inline {
	display: none;
}

/* 767 */
@media (max-width: @screen-xs-max) {
	.mobile-hide {
		display: none;
	}
	
	.mobile-show-inline {
		display: inline;
	}
}

/* 479 */
@media (max-width: 479px) {
	.mobile-only-show {
		display: block;
	}
	
	.mobile-only-hide {
		display: none;
	}
	
	.mobile-only-show-inline {
		display: inline;
	}
}

body {
	background: @background;
}

#header {
	text-align: center;
	
	.logo-pornblog {
		display: inline-block;
		margin: 30px 0 0 0;
		padding: 0;
		
		a {
			display: block;
			text-decoration: none;
			color: @blue;
			transition: color 0.2s;
			
			.notouch &:hover {
				color: @link-hover;
			}
		}
		
		#site-logo-svg {
			display: block;
			width: 400px;
			height: 83px;
		}
	}
	
	.language-container {
		position: absolute;
		top: 5px;
		right: 10px;
	}
	
	#language-switcher {
		display: block;
		cursor: pointer;
	}
}

/* 479 */
@media (max-width: @screen-xxs-max) {
	#header {
		.logo-pornblog {
			#site-logo-svg {
				width: 260px;
				height: 54px;
			}
		}
	}
}

/* 320 */
@media (max-width: 340px) {
	#header {
		.logo-pornblog {
			#site-logo-svg {
				width: 220px;
				height: 45px;
			}
		}
	}
}

#main {
	padding: 0 40px 50px;
	
	h1 {
		font-family: @font-title, serif;
		font-size: 60px;
		font-weight: normal;
		margin: 30px 0 10px;
	}
}

/* 1199 */
@media (max-width: @screen-md-max) {
	#main {
		padding-left: 30px;
		padding-right: 30px;
		
		h1 {
			font-size: 50px;
		}
	}
}

/* 767 */
@media (max-width: @screen-xs-max) {
	#main {
		h1 {
			font-size: 40px;
		}
	}
}

#footer {
}

.wrapper {
	position: relative;
	max-width: @screen-xlg-min; /*1440*/
	width: 100%;
	margin: 0 auto;
}

.btn-danger {
	margin-left: 10px;
	color: #fff;
	background-color: red;
	border: none;
}

/* ==== Éléments basiques ==== */
.post-img {
	position: relative;
	overflow: hidden;
	
	&:before {
		content: '';
		display: block;
	}
	
	img {
		position: absolute;
		left: 50%;
		top: 50%;
		width: 100%;
		height: auto;
		transform-origin: center center;
		transform: translate3D(-50%, -50%, 0);
	}
}

.post-text {
	position: relative;
}

.post-date {
	display: block;
	padding: 0;
	font-size: 16px;
	color: @blue;
}

.post-title {
	padding: 0;
	font-weight: normal;
	font-family: @font-title, serif;
	letter-spacing: -0.03em;
	line-height: 1;
}

.post-tags-list {
	display: block;
	margin: 0;
	padding: 0;
	font-size: 0;
	
	.post-tag {
		display: inline-block;
		height: 20px;
		padding: 0 13px;
		margin: 2px 0;
		text-transform: uppercase;
		font-size: 10px;
		line-height: 20px;
		color: #000;
		border: 1px solid @text;
		border-radius: 10px;
		
		& ~ .post-tag {
			margin-left: 10px;
		}
	}
}

/* 600 */
@media (max-width: 600px) {
	.post-tags-list {
		.post-tag {
			height: 16px;
			padding: 0 8px;
			font-size: 9px;
			line-height: 16px;
			border-radius: 8px;
			
			& ~ .post-tag {
				margin-left: 5px;
			}
		}
	}
}

.post-comments-links {
	margin-top: 30px;
	
	a {
		color: @blue;
		
		&.btn-comment {
			display: inline-block;
			padding: 7px 15px;
			margin-left: 10px;
			text-decoration: none;
			color: @text;
			background: @background;
			border: 1px solid @blue;
			border-radius: 17px;
			transition: all 0.2s;
			
			.notouch &:hover {
				color: #fff;
				background: @blue;
			}
		}
	}
}

.post-body {
	display: flex;
	flex-flow: row wrap;
	align-items: stretch;
	padding: 0 60px;
	
	a {
		color: @blue;
		
		.notouch &:hover {
			color: @text;
		}
	}
}

/* 1199 */
@media (max-width: @screen-md-max) {
	.post-body {
		padding: 0 60px;
	}
}

/* 767 */
@media (max-width: @screen-xs-max) {
	.post-body {
		padding: 0;
	}
}


.post-nav {
	position: relative;
	display: flex;
	flex-flow: row wrap;
	align-items: stretch;
	
	&:before,
	&:after {
		content: '';
		position: absolute;
		left: 10px;
		display: block;
		width: ~"calc(100% - 20px)";
		height: 1px;
		background: @text;
	}
	
	&:before {
		top: 0;
	}
	
	&:after {
		bottom: 0;
	}
	
	.post-nav-prev,
	.post-nav-next {
		position: relative;
		width: 50%;
		padding: 40px 0;
		
		&:after {
			content: '';
			position: absolute;
			top: 10px;
			display: block;
			width: 1px;
			height: ~"calc(100% - 20px)";
			background: @text;
		}
		
		a {
			position: relative;
			display: inline-block;
			text-decoration: none;
			color: @text;
			transition: padding 0.2s, margin 0.2s;
			
			.icon-f {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}
			
			.notouch &:hover {
				color: @link-hover;
			}
		}
	}
	
	.post-nav-prev {
		text-align: left;
		
		&:after {
			right: 0;
		}
		
		a {
			margin: 0 0 0 60px;
			padding: 0 0 0 22px;
			
			.icon-f {
				left: 0;
			}
			
			.notouch &:hover {
				margin: 0 0 0 55px;
				padding: 0 0 0 27px;
			}
		}
		
		& ~ .post-nav-next {
			margin-left: 0;
			
			&:after {
				display: none;
			}
		}
	}
	
	.post-nav-next {
		text-align: right;
		margin-left: 50%;
		
		&:after {
			left: 0;
		}
		
		a {
			margin: 0 60px 0 0;
			padding: 0 22px 0 0;
			
			.icon-f {
				right: 0;
			}
			
			.notouch &:hover {
				margin: 0 55px 0 0;
				padding: 0 27px 0 0;
			}
		}
	}
}

/* 600 */
@media (max-width: 600px) {
	.post-nav {
		.post-nav-prev {
			a {
				margin: 0 0 0 5px;
				padding: 0 0 0 22px;
				
				.notouch &:hover {
					margin: 0;
					padding: 0 0 0 27px;
				}
			}
		}
		
		.post-nav-next {
			a {
				margin: 0 5px 0 0;
				padding: 0 22px 0 0;
				
				.notouch &:hover {
					margin: 0;
					padding: 0 27px 0 0;
				}
			}
		}
	}
}

/* 479 */
@media (max-width: @screen-xxs-max) {
	.post-nav {
		.post-nav-prev,
		.post-nav-next {
			padding: 20px 0;
		}
	}
}


#blog-post-comments {
	border-top: 1px solid @blue;
	
	a {
		color: @blue;
	}
	
	.x-thread {
		padding: 40px;
		
		.thread-node-btn {
			color: @text;
			
			&.thread-node-btn-post {
				position: relative;
				display: inline-block;
				vertical-align: middle;
				padding: 14px 30px 12px 50px;
				text-decoration: none;
				font-size: 16px;
				background: @background;
				border: 1px solid @blue;
				border-radius: 25px;
				transition: all 0.2s;
				
				&:before {
					content: '+';
					position: absolute;
					top: 50%;
					left: 20px;
					display: block;
					font-size: 24px;
					transform: translateY(-50%);
				}
				
				.notouch &:hover {
					color: #ffffff;
					background: @blue;
					border-color: @background;
				}
			}
		}
		
		.thread-node-info-comment {
			font-size: 11px;
			color: @text;
		}
		
		.thread-header {
			margin-bottom: 30px;
			
			.thread-title,
			.thread-node-children-count {
				margin: 0;
				font-weight: normal;
				line-height: 1;
				font-family: @font-title, serif;
				font-size: 50px;
				font-variant: normal;
				letter-spacing: -0.03em;
			}
			
			.thread-node-children-count {
				display: inline-block;
				margin: 0 10px;
				color: @blue;
			}
			
			.thread-sort {
				margin-right: 20px;
				
				a {
					cursor: pointer;
					
					&.active {
						font-weight: 700;
					}
					
					&:hover {
						color: @text;
					}
				}
			}
			
			.thread-node-btn {
				&.thread-node-btn-post {
					vertical-align: bottom;
				}
			}
			
			.thread-node-info-comment {
				display: inline-block;
				max-width: 30%;
				margin: 0 0 0 20px;
			}
		}
		
		.thread-node-date {
			font-size: 12px;
		}
		
		.thread-node-message {
			line-height: 1.5;
		}
		
		.thread-node-children {
			.thread-node-metadata {
				.icon-f {
					cursor: pointer;
					transition: all 0.2s;
					
					&.icf-thumb-down {
						margin-left: 10px;
					}
					
					.notouch &:hover {
						color: @blue;
					}
				}
				
				a {
					font-size: 12px;
					margin-left: 10px;
					color: @text;
					
					.notouch &:hover {
						text-decoration: underline;
					}
				}
			}
			
			.thread-node-children-show,
			.thread-node-children-hide {
				text-decoration: underline;
				cursor: pointer;
			}
		}
		
		.thread-node-children-load-next-btn,
		.thread-node-children-load-all-btn {
			/* 480 */
			@media (min-width: @screen-xs-min) {
				display: inline-block;
				font-size: 1.2em;
				padding: 5px 4px;
				
				&:hover {
					text-decoration: underline;
				}
			}
			
			/* 479 */
			@media (max-width: @screen-xxs-max) {
				display: block;
				font-size: 1.2em;
				padding: 5px 0;
				
				& + .thread-node-info-comment {
					margin-top: 10px;
				}
			}
		}
		
		.form-group {
			margin-left: 0;
			margin-right: 0;
			
			& ~ .form-group {
				margin-top: 15px;
			}
			
			&.form-buttons {
				text-align: right;
				
				.btn-link,
				.thread-node-btn {
					color: @text;
					position: relative;
					display: inline-block;
					vertical-align: text-bottom;
					padding: 16px 0 13px;
					font-size: 16px;
					line-height: 1;
				}
				
				.btn-link {
					margin-right: 15px;
					
					.notouch &:hover {
						text-decoration: none;
					}
				}
				
				.thread-node-btn {
					padding: 14px 30px 12px;
					background: @background;
					border: 1px solid @blue;
					border-radius: 25px;
					transition: all 0.2s;
					
					.notouch &:hover {
						text-decoration: none;
						color: #ffffff;
						background: @blue;
						border-color: @background;
					}
					
					&:focus {
						outline: none;
					}
				}
			}
		}
		
		.form-control {
			&.emojionearea {
				padding: 0;
				font-size: 17px;
			}
			
			.emojionearea-editor {
				height: 100%;
			}
		}
	}
}

/* 1199 */
@media (max-width: @screen-md-max) {
	#blog-post-comments {
		.x-thread {
			padding: 30px;
		}
	}
}

/* 991 */
@media (max-width: @screen-sm-max) {
	#blog-post-comments {
		.x-thread {
			.thread-header {
				.thread-node-info-comment {
					display: block;
					max-width: inherit;
					width: 100%;
					margin: 20px 0 0 0;
					text-align: center;
				}
			}
		}
	}
}

/* 767 */
@media (max-width: @screen-xs-max) {
	#blog-post-comments {
		.x-thread {
			padding-left: 0;
			padding-right: 0;
		}
	}
	
	.post-nav {
		
		&:before,
		&:after {
			left: 0;
			width: 100%;
		}
	}
}

/* 479 */
@media (max-width: @screen-xxs-max) {
	#blog-post-comments {
		.x-thread {
			.thread-node-btn.thread-node-btn-post {
				margin-top: 10px;
			}
			
			.thread-header {
				margin-bottom: 20px;
				
				.thread-title,
				.thread-node-children-count {
					font-size: 40px;
				}
				
				.thread-node-children-count {
					margin-right: 20px;
				}
				
				.thread-node-btn.thread-node-btn-sort {
					font-size: 24px;
				}
			}
			
			.thread-node {
				.thread-node-content {
					.thread-node-poster {
						overflow: hidden;
						
						.thread-node-date {
							padding-top: 3px;
							font-size: 12px;
						}
					}
				}
				
				.thread-node-children-nodes {
					padding-left: 0;
				}
			}
		}
	}
}

/* 320 */
@media (max-width: 320px) {
	#blog-post-comments {
		.x-thread {
			padding: 20px;
			
			.thread-header {
				.thread-title,
				.thread-node-children-count {
					font-size: 30px;
				}
			}
		}
	}
}


/* ==== Contexte & Surcharges ==== */
.post-block {
	position: relative;
	padding: 40px 0;
	text-align: left;
	
	.post-img {
		margin-bottom: 35px;
		
		&:before {
			padding-top: 66.666%;
		}
		
		a {
			img {
				transition: all 2s ease-out;
			}
			
			.notouch &:hover {
				img {
					width: 110%;
				}
			}
		}
	}
	
	.post-date {
		margin: 0 0 10px 0;
	}
	
	.post-title {
		margin: 0 0 18px 0;
		font-size: 50px;
		
		a {
			text-decoration: none;
			color: @text;
			transition: color 0.2s;
			
			.notouch &:hover {
				color: @blue;
			}
		}
	}
	
	.post-tags-list {
		margin: 0 0 18px 0;
	}
	
	.post-intro {
		text-decoration: none;
		font-size: 16px;
		line-height: 1.5;
		color: @text;
		
		&:after {
			content: '…';
		}
	}
	
	.post-content {
		font-size: 16px;
		line-height: 1.5;
		color: @text;
		
		a {
			color: @blue;
			
			&.btn-nav {
				color: @text;
				
				.notouch & {
					&:hover,
					&:active,
					&:focus {
						color: #fff;
					}
				}
			}
		}
	}
}

/* 1199 */
@media (max-width: @screen-md-max) {
	.post-block {
		padding: 30px 0;
		
		.post-title {
			font-size: 40px;
		}
	}
}


.posts-list {
	.post-block {
		border-bottom: 1px solid @blue;
	}
}

/* 1199 */
@media (max-width: @screen-md-max) {
	.posts-list {
		.post-block {
			&.post-big {
				.post-title {
					font-size: 50px;
				}
			}
		}
	}
}

/* 700 */
@media (max-width: 700px) {
	.posts-list {
		.post-block {
			&.post-big {
				.post-title {
					font-size: 40px;
				}
			}
		}
	}
}

/* 479 */
@media (max-width: @screen-xxs-max) {
	.posts-list {
		.post-block {
			&.post-big {
				.post-read-more {
					font-size: 14px;
				}
			}
		}
	}
}


.post-banner {
	position: relative;
	margin: 30px 0;
	overflow: hidden;
	
	.post-img {
		&:before {
			padding-top: 34.027%;
		}
	}
	
	.post-tags-list {
		text-align: center;
	}
}

/* 479 */
@media (max-width: @screen-xxs-max) {
	.post-banner {
		margin: 20px 0;
		
		.post-tags-list {
			bottom: 15px;
		}
	}
}


.post-content {
	width: 100%;
	
	.post-content-desc {
		position: relative;
		min-height: 250px;
	}
	
	h2, h3, h4, h5, h6, blockquote {
		margin: 0 0 30px 0;
		padding: 0;
		font-family: @font-title;
		font-weight: normal;
		line-height: 1;
	}
	
	h2 {
		font-size: 60px;
		margin-bottom: 50px;
	}
	
	h3 {
		font-size: 50px;
	}
	
	h4 {
		font-size: 40px;
	}
	
	h5 {
		font-size: 30px;
	}
	
	h6 {
		font-size: 20px;
	}
	
	blockquote {
		padding: 20px 0;
		text-align: center;
		font-size: 50px;
		border-left: none;
		border-top: 1px solid @text;
		border-bottom: 1px solid @text;
		
		&:before {
			content: "“ ";
		}
		
		&:after {
			content: " ”";
		}
	}
	
	p {
		margin-bottom: 16px;
		font-size: 16px;
		line-height: 1.5;
	}
	
	ul, ol {
		margin: 0 0 16px 1em;
		padding: 0;
		font-size: 16px;
	}
	
	img {
		width: 100%;
		height: auto;
		margin: 0 0 16px 0;
	}
	
	figure {
		margin: 0 0 16px 0;
		
		img {
			margin-bottom: 0;
		}
		
		figcaption {
			margin-top: 5px;
			text-align: center;
			font-style: italic;
		}
	}
}

/* 1199 */
@media (max-width: @screen-md-max) {
	.post-content {
		padding: 0 30px;
		border-right: none;
	}
}

/* 767 */
@media (max-width: @screen-xs-max) {
	.post-content {
		padding: 0;
		
		ul,
		ol {
			margin-left: 1em;
		}
		
		h2 {
			font-size: 40px;
			margin-bottom: 30px;
		}
		
		h3 {
			font-size: 35px;
		}
		
		h4 {
			font-size: 30px;
		}
		
		h5 {
			font-size: 25px;
		}
		
		h6 {
			font-size: 20px;
		}
	}
}

/* 600 */
@media (max-width: 600px) {
	.post-content {
		h2, h3, h4, h5, h6, blockquote {
			margin: 0 0 25px 0;
		}
		ul,
		ol {
			margin-left: 1em;
		}
		
		h2 {
			font-size: 30px;
		}
		
		h3 {
			font-size: 25px;
		}
		
		h4 {
			font-size: 20px;
		}
		
		h5 {
			font-size: 22px;
		}
		
		h6 {
			font-size: 20px;
		}
		
		blockquote {
			padding: 10px 0;
			font-size: 25px;
		}
	}
}


.post-aside {
	position: relative;
	width: 33.333%;
	border-left: 1px solid @text;
	
	.post-aside-title {
		margin: 0 0 50px 60px;
		font-family: @font-title;
		font-weight: normal;
		font-size: 50px;
		line-height: 1;
	}
	
	.post-block {
		.post-date {
			color: @text;
		}
		
		& ~ .post-block {
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 10px;
				display: block;
				width: ~"calc(100% - 20px)";
				height: 1px;
				background: @text;
			}
		}
	}
}

/* 1199 */
@media (max-width: @screen-md-max) {
	.post-aside {
		display: flex;
		flex-flow: row wrap;
		align-items: stretch;
		width: 100%;
		
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 10px;
			display: block;
			width: ~"calc(100% - 20px)";
			height: 1px;
			background: @text;
		}
		
		.post-aside-title {
			width: 100%;
			margin: 20px 0 0 10px;
		}
		
		.post-block {
			width: 33.333%;
			
			& ~ .post-block {
				&:before {
					top: 10px;
					left: 0;
					display: block;
					width: 1px;
					height: ~"calc(100% - 20px)";
				}
			}
		}
	}
}

/* 767 */
@media (max-width: @screen-xs-max) {
	.post-aside {
		.post-block {
			width: 50%;
		}
	}
}

/* 600 */
@media (max-width: 600px) {
	.post-aside {
		.post-aside-title {
			font-size: 25px;
		}
		
		.post-block {
			.post-title {
				font-size: 25px;
			}
		}
	}
}


.post {
	color: @text;
	
	#main & {
		overflow: hidden;
	}
	
	& > .post-date {
		margin-bottom: 30px;
		text-align: center;
	}
	
	& > .post-title {
		margin: 0 auto 100px;
		font-size: 80px;
		line-height: 80px;
	}
}

/* 767 */
@media (max-width: @screen-xs-max) {
	.post {
		& > .post-date {
			margin-bottom: 20px;
		}
		
		& > .post-title {
			margin-bottom: 20px;
		}
	}
}

/* 600 */
@media (max-width: 600px) {
	.post {
		& > .post-date {
			margin-bottom: 15px;
		}
		
		& > .post-title {
			max-width: 100%;
			margin-bottom: 30px;
			font-size: 40px;
			line-height: 40px;
		}
	}
}

/* ==== Page ==== */
.http-status {
	text-align: center;
	
	h1 {
		margin-bottom: 30px;
	}
	
	h4 {
		a {
			color: @text;
		}
	}
}


.form-block {
	overflow: hidden;
	padding-left: 120px;
	position: relative;
}

.form-block-desc {
	font-size: 16px;
	margin: 30px 0;
	
	.see-more {
		display: none;
		bottom: 0;
		margin: 0;
		position: absolute;
		text-align: center;
		width: 100%;
		
		button {
			background: @blue;
			border: 0;
			border-radius: 4px;
			color: #fff;
			font-size: 14px;
			font-weight: bold;
			line-height: 22px;
			padding: 10px 0;
			white-space: nowrap;
			width: 100%;
			
			&:hover,
			&:active,
			&:focus {
				background: @blue-dark;
				color: #fff;
				text-decoration: none;
			}
			
			&:active,
			&:focus {
				outline: 1px solid #fff;
				outline-offset: -5px;
			}
		}
	}
	
	&.is-cropped {
		max-height: 260px;
		overflow: hidden;
		position: relative;
		
		&.is-cropped-full {
			max-height: 42px;
		}
		
		.see-more {
			display: block;
		}
	}
}

/* 479 */
@media (max-width: @screen-xxs-max) {
	.form-block-desc {
		.see-more {
			button {
				font-size: 12px;
			}
		}
	}
}

.form-block-see-answers {
	font-size: 16px;
	margin: 50px 0;
	text-align: center;
}

.form-block-input {
	h3 {
		.pop-info-btn {
			line-height: 24px;
			vertical-align: top;
		}
	}
	
	.form-option-line {
		font-size: 0;
		margin-bottom: 4px;
		padding-left: 40px;
		white-space: nowrap;
		
		* {
			vertical-align: top;
		}
		
		input {
			height: 20px;
			margin: 5px 4px 5px 0;
			width: 20px;
			
			&:checked + label {
				background: @blue;
				border-radius: 5px;
				color: #fff;
			}
		}
		
		label {
			font-size: 18px;
			font-weight: normal;
			line-height: 22px;
			max-width: calc(~"100% - 24px");
			padding: 4px 8px;
			white-space: normal;
			
			.pop-info-btn {
				line-height: 22px;
			}
		}
	}
}

/* 767 */
@media (max-width: @screen-xs-max) {
	.form-block-input {
		.form-option-line {
			padding-left: 20px;
		}
	}
}

.form-block-answer,
.form-block-input {
	margin: 30px 0;
	
	h3 {
		font-weight: bold;
		font-size: 22px;
		line-height: 24px;
		margin: 30px 0 10px;
	}
}

.form-block-answer {
	h3 {
		font-size: 20px;
		font-weight: normal;
	}
	
	.form-block-answer-line {
		font-weight: bold;
		color: @blue-progress;
		padding-left: 20px;
		
		.form-block-answer-percent {
			border: 1px solid @blue-progress;
			display: inline-block;
			position: relative;
			height: 15px;
			margin-right: 6px;
			vertical-align: text-top;
			width: 50px;
			
			.form-block-answer-percent-bar {
				background: @blue-progress;
				bottom: 0;
				display: block;
				left: 0;
				position: absolute;
				top: 0;
			}
		}
	}
}

.form-block-answer-count {
	color: @blue;
	display: inline-block;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	
	&:not(:last-child) {
		margin: 0 20px 10px 0;
	}
}

.btn-nav {
	background: @background;
	border: 1px solid @blue;
	border-radius: 20px;
	color: @text;
	display: inline-block;
	line-height: 24px;
	padding: 7px 15px;
	position: relative;
	text-decoration: none;
	transition: all 0.2s;
	
	&:hover,
	&:active,
	&:focus {
		outline: none;
		text-decoration: none;
	}
	
	* {
		display: inline-block;
		line-height: 24px;
		vertical-align: top;
	}
	
	.notouch & {
		&:active,
		&:focus,
		&:hover {
			color: #fff;
			background: @blue;
		}
		
		&:active,
		&:focus {
			&::after {
				border: 1px solid #fff;
				border-radius: 16px;
				content: " ";
				position: absolute;
				bottom: 3px;
				left: 3px;
				right: 3px;
				top: 3px;
			}
		}
	}
}

/* 479 */
@media (max-width: @screen-xxs-max) {
	.btn-nav {
		display: block;
		text-align: center;
	}
}


.post-share {
	ul {
		list-style: none;
	}
	
	.post-share-item {
		& ~ .post-share-item {
			margin-top: 20px;
		}
		
		a {
			background: @blue;
			border-radius: 50%;
			display: block;
			height: 60px;
			overflow: hidden;
			position: relative;
			transition: all 0.2s;
			width: 60px;
			
			.notouch &:hover {
				background: @link-hover;
			}
			
			&:focus,
			&:active {
				outline: none;
				
				&::after {
					border: 1px solid #fff;
					border-radius: 50%;
					content: " ";
					position: absolute;
					bottom: 3px;
					left: 3px;
					right: 3px;
					top: 3px;
				}
			}
		}
		
		img {
			position: absolute;
			top: 50%;
			left: 50%;
			height: auto;
			transform: translate3d(-50%, -50%, 0);
			
			&.facebook {
				width: 16px;
			}
			
			&.twitter {
				width: 25px;
			}
			
			&.mail,
			&.comments {
				width: 27px;
			}
		}
	}
}

.post-content .post-content-desc,
.form-block {
	padding-left: 120px;
	
	.post-share {
		position: absolute;
		top: 0;
		left: 0;
		
		ul {
			margin: 0;
			padding: 0;
		}
	}
}

/* 600 */
@media (max-width: 600px) {
	.post-share {
		.post-share-item {
			a {
				width: 40px;
				height: 40px;
			}
			
			img {
				&.facebook {
					width: 13px;
				}
				
				&.twitter {
					width: 23px;
				}
				
				&.mail {
					width: 24px;
				}
			}
		}
	}
	
	.post-content .post-content-desc,
	.form-block {
		padding-left: 0;
		
		.post-share {
			position: static;
			margin: 10px 0;
			
			ul {
				display: flex;
				justify-content: space-around;
				margin: 0 auto;
				max-width: 80%;
			}
			
			.post-share-item {
				& ~ .post-share-item {
					margin-top: 0;
				}
			}
		}
	}
}

/* 479 */
@media (max-width: @screen-xxs-max) {
	.post-share {
		.post-share-item {
			a {
				width: 34px;
				height: 34px;
			}
			
			img {
				&.facebook {
					width: 9px;
				}
				
				&.twitter {
					width: 17px;
				}
				
				&.mail {
					width: 18px;
				}
			}
		}
	}
}

.survey {
	
	/* 479 */
	@media (max-width: 479px) {
		#header .logo-pornblog {
			margin: 10px 0 0;
			
			#site-logo-svg {
				width: 220px;
				height: 45px;
			}
		}
		
		#main {
			padding-left: 15px;
			padding-right: 15px;
			
			h1 {
				margin-top: 10px;
				font-size: 30px;
			}
			
			.form-block-desc {
				margin: 10px 0;
				font-size: 14px;
				
				&.is-cropped {
					max-height: 154px;
				}
			}
			
			.form-block-input {
				margin: 10px 0;
				
				h3 {
					margin-top: 10px;
				}
				
				h3,
				.form-option-line label {
					font-size: 16px;
					line-height: 19px;
				}
			}
		}
	}
	/* 360 */
	@media (max-width: 360px) {
		#header .logo-pornblog {
			#site-logo-svg {
				width: 150px;
				height: 31px;
			}
		}
		
		#main {
			padding-left: 10px;
			padding-right: 10px;
			
			h1 {
				font-size: 26px;
			}
			
			h3 {
				margin-top: 10px;
				font-size: 20px;
			}
			
			.form-block-desc {
				margin: 0;
				
				&.is-cropped {
					max-height: 154px;
				}
			}
			
			.form-block-input {
				margin: 5px 0 0;
				
				.form-option-line {
					margin-bottom: 0;
				}
				
				h3 {
					margin: 5px 0;
				}
				
				h3,
				.form-option-line label {
					font-size: 15px;
					line-height: 18px;
				}
			}
		}
	}
	/* 320 */
	@media (max-width: 320px) {
		#header .logo-pornblog {
			#site-logo-svg {
				width: 116px;
				height: 24px;
			}
		}
		
		#main {
			h1 {
				font-size: 24px;
				margin: 5px 0 10px;
			}
			
			.form-block-desc {
				font-size: 13px;
				
				&.is-cropped {
					max-height: 130px;
				}
				
				.see-more button {
					padding: 2px 0;
				}
			}
			
			.form-block-input {
				.form-option-line {
					input {
						margin-bottom: 2px;
						margin-top: 2px;
					}
				}
				
				h3,
				.form-option-line label {
					font-size: 13px;
					line-height: 16px;
					font-weight: bold;
				}
			}
		}
	}
}