
.pagination {
	margin: 20px 0;
	overflow: hidden;
	clear: left;
	white-space: nowrap;
	ul {
		margin: 0;
		padding: 0 0 0 1px;
		vertical-align: middle;
		text-align: center;
		li {
			display: inline-block;
			padding: 0;
			margin: 0 5px;
			a {
				display: inline-block;
				border: 1px solid @blue;
				font-size: 14px;
				margin-bottom: 2px;
				padding: 4px 8px;
				text-decoration: none;
				color: @text;
				border-radius: 5px;
				transition: all 0.15s;
				&:hover{
					background-color: @blue;
					color: #ffffff;
				}
				&.active {
					background-color: @blue;
					color: #ffffff;
					cursor: default;
					padding-left: 8px;
					padding-right: 8px;
				}
				&.no-page {
					background: none;
					border: 0;
					color: @blue;
					padding: 5px 4px;
					* {
						text-decoration: none;
					}
					&:hover{
						* {
							text-decoration: underline;
						}
					}
					.icon-f {
						vertical-align: middle;
						text-decoration: none;
					}
				}
			}
			&.no-page {
				margin: 0;
				a {
					display: inline-block;
					font-size: 14px;
					margin-bottom: 2px;
					padding: 5px 0;
					text-decoration: none;
					border: 0;
					background: none;
					color: @blue;
					cursor: default;
				}
			}
			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}
	&.align-left {
		ul {
			text-align: left;
			li:first-child {
				margin-left: 0;
			}
		}
	}
	&.big {
		ul {
			li a {
				font-size: 18px;
				padding: 0 8px;
			}
		}
	}
	&.ordered-label-list{
		ul{
			li{
				margin: 0 6px 5px 0;
				a{
					background: @background;
					border: 0;
					color: @blue;
					font-size: 14px;
					font-weight: normal;
					line-height: 26px;
					margin: 0;
					text-decoration: none;
					&, &.no-page {
						padding: 0 6px;
					}
					&:hover {
						background: @blue;
						color: @background;
						text-decoration: none;
					}
					&.active{
						background: @blue;
						color: @background;
						cursor: default;
						font-weight: bold;
					}
					&.no-page {
						background: none;
						border: 0;
						font-weight: bold;
						text-decoration: underline;
						&:hover{
							color: @background;
							text-decoration: none;
						}
					}
				}
				
				&.no-page {
					a {
						padding: 0;
						background: none;
						cursor: default;
						&:hover {
							color: @background;
						}
					}
				}
			}
		}
	}
}

.x-content .pagination {
	ul {
		li {
			a {
				color: @text;
				background: @background;
				&:hover,
				&.active {
					background-color: @blue;
					color: #ffffff;
				}
				&.no-page {
					&:hover {
						color: @background;
						background: none;
					}
				}
			}
		}
	}
}
