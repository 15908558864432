.x-thread {
	.thread-node-btn {
		&,
		a {
			color: #fff;
			cursor: pointer;
		}
	}
	
	.thread-node {
		margin: 15px 0;
		padding: 15px;
		border: 1px solid @blue;
		border-radius: 15px;
		.thread-node-content {
			padding-left: 0;
			.thread-node-poster {
				.flag-small {
					vertical-align: baseline;
				}
				.thread-node-poster-name {
					font-weight: 700;
					font-size: 16px;
					.verified,
					.poster-stats {
						display: none;
					}
					&.poster-owner {
						color: @blue;
					}
				}
				.thread-node-date {
					float: right;
				}
			}
			.thread-node-message {
				font-size: 16px;
				margin: 10px 0;
				.verified {
					display: none;
				}
			}
			.thread-node-metadata {
				.thread-node-btn-post,
				.thread-node-btn-edit,
				.thread-node-btn-delete,
				.thread-node-btn-restore,
				.thread-node-btn-report {
					cursor: pointer;
				}
			}
			.thread-node-children {
				padding-top: 10px;
			}
		}
		.thread-node-children-nodes {
			padding-left: 20px;
			.thread-node {
				border-width: 0 0 0 1px;
				border-radius: 0;
			}
		}
	}
	form {
		.x-popup.below .x-popup-arrow {
			border-bottom-color: @blue-theme-light-border;
		}
		.x-popup-content {
			padding: 4px 10px;
			background: @blue-theme-light-border;
			box-shadow: 2px 3px 10px rgba(0,0,55,.5);
			color: #fff;
		}
	}
}

.thread-post-report-form-popup {
	.form-group {
		margin-left: 0;
		margin-right: 0;
		label {
			color: #fff;
		}
		&.form-field-report-post_reason {
			.radio {
				display: inline-block;
				margin-right: 20px;
			}
		}
	}
	form {
		.x-popup.below .x-popup-arrow {
			border-bottom-color: @blue-theme-light-border;
		}
		.x-popup-content {
			padding: 4px 10px;
			background: @blue-theme-light-border;
			box-shadow: 2px 3px 10px rgba(0, 0, 55, .5);
			color: #fff;
		}
	}
}

@media (max-width: @screen-sm-min - 1) {
	.thread-post-report-form-popup {
		.form-group.form-field-report-post_reason {
			.radio {
				display: block;
				margin-right: 0;
			}
		}
	}
}